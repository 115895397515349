import Vue from "vue";
import axios from "axios";

export default axios.create({
  baseURL: window.CONFIG.api,
  auth: {
    username: "mutuel_access",
    password: "mutuel_dev"
  },
  headers: {
    "Access-Control-Allow-Origin": "*.mltgroup-conveyor.com",
    "Content-Type": "application/json"
  },
  onDownloadProgress: (e) => {
    //console.log(e);
    const total = e.currentTarget.getResponseHeader("content-length");
    const length = e.currentTarget.response.length;
    const loaded = Math.floor((length / total) * 100);

    Vue.prototype.$load_total = total;
    Vue.prototype.$load_length = length;
    Vue.prototype.$loaded = isNaN(loaded) || !total ? null : loaded;

    //console.log(Vue.prototype.$loaded);
  }
});
